import { Spin } from "antd";
import React, { Suspense } from "react";
import { AuthenticationWrap } from "./style";

const AuthLayout = (WraperContent) => {
  return function () {
    return (
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <AuthenticationWrap
          style={{
            backgroundImage: `url("${require("../../static/img/admin-bg-light.png")}")`,
            minHeight: "100vh",
          }}
        >
          <div className="ninjadash-authentication-wrap">
            <div className="ninjadash-authentication-brand">
              <img
                src={require(`../../static/img/head-logo.svg`).default}
                alt=""
              />
            </div>
            <WraperContent />
          </div>
        </AuthenticationWrap>
      </Suspense>
    );
  };
};

export default AuthLayout;
