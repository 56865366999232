/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import React, { useState } from "react";
import { Upload, message, Image } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

const ellipsis = (text, size) => {
  return `${text.split(" ").slice(0, size).join(" ")}...`;
};

const idGenerator = (events, length = 1) => {
  const arrayData = [];
  events.map((data) => {
    return arrayData.push(parseInt(data.id, 10));
  });
  const number = (Math.max(...arrayData) + 1).toString();
  return number.length < length
    ? `${"0".repeat(length - number.length)}${number}`
    : number;
};

const ImageUploader = ({ imageUrl, handleChange, form, page }) => {
  const [loadingImg, setLoadingImg] = useState(false);
  const multiImage = page == "addPainting" ? true : false;

  // Image Uploader
  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result, img));
    reader.readAsDataURL(img);
  };

  // Before Image Upload
  const beforeUpload = (file) => {
    if (file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        message.error("Image must be smaller than 5MB!");
      }
      return isJpgOrPng && isLt5M;
    }
  };
  // Handle Image Change
  const handleImageChange = (info) => {
    if (info.file && info.file.originFileObj instanceof Blob) {
      getBase64(info.file.originFileObj, (base64) => {
        setLoadingImg(false);
        handleChange(info.file.originFileObj, base64);
      });
    } else {
      setLoadingImg(false);
      handleChange(null, null);
    }
  };

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}> Upload </div>
    </div>
  );

  // console.log(imageUrl)
  return (
    <>
      <Upload
        name="image"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={multiImage}
        multiple={multiImage}
        maxCount={multiImage ? 10 : 1}
        accept=".jpg, .jpeg, .png"
        beforeUpload={beforeUpload}
        onChange={handleImageChange}
        customRequest={() => {}}
      >
        {!multiImage && imageUrl ? (
          <img src={imageUrl} alt="image" style={{ width: "100%" }} />
        ) : (
          uploadButton
        )}
      </Upload>
    </>
  );
};

// Cover And Multi Image Preview
const ImagePreview = ({ imageUrl, baseURL }) => {
  // console.log(Array.isArray(imageUrl));
  return (
    <div className="d-flex align-items-center">
      {Array.isArray(imageUrl) ? (
        imageUrl?.map((data, i) => {
          return (
            <div className="multi-image-preview">
              <Image key={i} src={baseURL + data} />
            </div>
          );
        })
      ) : (
        <div className="multi-image-preview">
          <Image src={imageUrl} />
        </div>
      )}
    </div>
  );
};

// Get User Data From Local Storage
const userData = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || {};
  return userData;
};

// Get Remember Email for LogIn
const remEmail = (form, setRemMe) => {
  const remMe = localStorage.getItem("rememberedEmail");
  if (remMe) {
    console.log(remMe);
    form.setFieldsValue({
      email: remMe,
    });
    setRemMe(true);
  }
};

// Multi Search Switching - Dropdown - Pending Registration and All Users
const getPlaceholderText = (searchType) => {
  switch (searchType) {
    case "name":
      return "Name";
    case "username":
      return "UserName";
    case "plan":
      return "Plan";
    case "email":
      return "Email";
    default:
      return "Phone";
  }
};

// Multi Search Switching - Dropdown - Pending Registration and All Users
const getPlaceholderType = (searchType) => {
  switch (searchType) {
    case "name":
      return "text";
    case "username":
      return "text";
    case "plan":
      return "text";
    case "email":
      return "email";
    default:
      return "number";
  }
};

const phoneValidation = (_, value) => {
  if(value){
    const isValidLength = value.length >= 8 && value.length <= 15;
    const isValidPattern = /^[\d+ -]+$/.test(value);
    if (isValidLength && isValidPattern) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error("Please enter a valid phone number (8 to 15 digits, +, or -)")
    );
  } else {
    return Promise.resolve();
  }
};

export const validateEmail = (rule, value, callback) => {
  const trimmedValue = value.trim();
  if (!trimmedValue || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(trimmedValue)) {
    callback();
  } else {
    callback('Please enter a valid email address');
  }
};

export {
  ellipsis,
  idGenerator,
  ImageUploader,
  ImagePreview,
  remEmail,
  userData,
  getPlaceholderText,
  getPlaceholderType,
  phoneValidation
};
