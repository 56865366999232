import { apiHeader, apiNoHeader } from "./BaseUrl";

// ========================================== Authentication ==========================================
// Auth - Login
export const loginUser = async (values) => {
  let data;
  await apiHeader
    .post("/auth/token", {
      email: values.email,
      password: values.password,
      device_token: "string",
      // device_type: "web",
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Logout
export const logoutUser = async () => {
  const refresh_token = localStorage.getItem('refresh_token') || {};
  let data;
  await apiHeader
    .post("/auth/logout", {
      refresh_token: refresh_token,
      device_token: "string"
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Forgot Password
export const forgetPassword = async (values) => {
  let data;
  await apiHeader
    .post("/auth/forgotpwd", {
      email: values.email,
      // password: values.password,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth Refresh Token
export const refreshAccessToken = async (refreshToken) => {
  let data;
  await apiHeader
    .post("/auth/token/refresh", {
      refresh_token: refreshToken,
    })
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Auth - Reset Password Page Redirection Api
// export const resetPasswordPage = async (token) => {
//   let data;
//   await apiHeader
//     .post("/auth/forgotpwd/verify/email", {
//       forgot_password_token:token
//     })
//     .then((res) => {
//       data = {
//         data: res,
//       };
//     })
//     .catch((err) => {
//       if (err.response) {
//         data = {
//           ...err.response,
//         };
//       }
//     });
//   return data;
// };
export const resetPasswordPage = async (token) => {
  let data;
  await apiHeader
    .get(`/auth/forgotpwd/email?forgot_pwd_token=${token}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// Auth - Reset Password Api
export const resetPassword = async (values, token) => {
  let data;
  await apiHeader
  .post("/auth/forgotpwd/reset", {
    email: values.email,
    forgot_pwd_token: token,
    new_password: values.newpassword
  })
  .then((res) => {
    data = {
      status: res.status,
      data: res.data,
    };
  })
  .catch((e) => {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  });
  return data;
};

// ========================================== Dashboard Stats ==========================================
// Site Setting
export const getDashboardStats = async () => {
  let data;
  await apiHeader
    .get(`/dashboard`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// ========================================== Site Settings ==========================================
// Site Setting
export const getSiteSettings = async () => {
  let data;
  await apiHeader
    .get(`/settings`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Site Setting - Update
export const updateSiteSettings = async (values, imageResponse) => {
  let data;
  await apiHeader
    .put("/settings", {
      logo: imageResponse,
      "contact_information": {
        phone: values.phone,
        email: values.email,
        address: values.address,
      },
      "social_media_links": {
        facebook: values.facebook,
        linkedin: values.linkedin,
        instagram: values.instagram,
        twitter: values.twitter,
        youtube: values.youtube,
      },
      "app_links": {
        google_play:values.googlelink,
        app_store: values.appstorelink
      },
      // "footer": {
      //   "title": "string",
      //   "powered_by": "string",
      //   "copyright": {
      //     "year": "string",
      //     "use_current_year": true
      //   }
      // }
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Site Post rate Setting Update
export const updatePostRateSettings = async (values) => {
  let data;
  await apiHeader
    .put("/settings/post-rate", {
      post_rate: values.individual_user_post_rate,
      subscription_rate: values.business_user_post_rate,
      number_of_posts: values.num_of_posts,
      

    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Notification
export const notificationUpdate = async (values) => {
  let data;
  await apiHeader
    .post(`/notifications`, {
      notify_type: values?.notification_type,
      subject: values?.notification_subject,
      message: values?.notification_details,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      console.log(err.response);
      if (err) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// ========================================== User Profile ==========================================
// User Profile Get Data 
export const getProfile = async () => {
  let data;
  await apiHeader
    .get("/user/profile")
    .then((res) => {
      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// User Profile Edit Data
export const editProfile = async (id, values,imageURL) => {
  let data;
  await apiHeader
    .put(`/user/profile?id=${id}`, {
      first_name: values.firstname,
      last_name: values.lastname,
      // slug: values.phone.slug,
      // code: values.phone.code,
      // phone_number: values.phone.number,
      img:imageURL,
      email:values?.email
    })
    .then((res) => {

      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
    return data;
};

// ========================================== Upload Image ==========================================
export const uploadImage = async (image) => {
  let data;
  const apiUrl = "/img/upload";
  const formData = new FormData();
  formData.append("img", image);
  try {
    const res = await apiHeader.post(apiUrl, formData);
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }
  return data;
};

// ========================================== Change User Password ==========================================
export const changeUserPassword = async (values) => {
  let data;
  await apiHeader
    .patch("/user/password/reset", {
      "current_password": values.currentpassword,
      "new_password": values.newpassword
    })
    .then((res) => {

      data = {
        status: res.status,
        data: res.data,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
    return data;
};

// CMS Get
export const cmsGet = async (type) => {
  let data;
  await apiHeader
    // .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .get(`cms/${type}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS Create 
export const cmsCreate = async (type,values) => {
  let data;
  await apiHeader
    .post(`cms/${type}`, {
      title: values.title,
      description: values.description,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS Update
export const cmsUpdate = async (type, formData) => {
  let data;
  await apiHeader
    .put(`/cms/${type}/`, formData).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS Delete
export const cmsDelete = async (type,id) => {
  let data;
  await apiHeader
    .delete(`/cms/${type}/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// CMS Status Update
export const cmsStatusUpdate = async (type,id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/cms/${type}/${id}/status`, {
      status: statusActive,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};
// CMS Swap
export const cmsSwap = async (type,new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/cms/sort-order/${type}`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// /Banner Get
export const bannerGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/banners?${search && `search=${search}`}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Banner Create
export const bannerCreate = async (values, imageResponseWeb, imageResponse) => {
  const requestData = {
    alt: values.name,
    description: values.description,
    img_mobile: imageResponse || "",
    img_web: imageResponseWeb || "",
    img_url_mobile: values.imageUrlmobile || "",
    img_url_web: values.imageUrlWeb || ""
  };

  let data;

  await apiHeader
    .post(`banners`, requestData)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });

  return data;
};


// Banner Update
export const bannerUpdate = async (values,imageResponseWeb,imageResponse,id) => {
  const requestData = {
    alt: values.name,
    description: values.description,
    img_mobile: imageResponse || "",
    img_web: imageResponseWeb || "",
    img_url_mobile: values.imageUrlmobile || "",
    img_url_web: values.imageUrlWeb || ""
  };
  
  let data;
  await apiHeader
    .put(`banners/${id}`, requestData
    ).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Banner Delete
export const bannerDelete = async (id) => {
  let data;
  await apiHeader
    .delete(`/banners/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Banner Status Update
export const bannerStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/banners/${id}/status?status=${statusActive}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// Banner Swap
export const bannerSwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/banners/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// All Product
export const products = async (limit, offset, filter, title, category, lessor, userType, rental ) => {
  let data;
  let queryParams = `?filter=${filter}&limit=${limit}&offset=${offset}`;
  if (title) queryParams += `&title=${title}`;
  if (category) queryParams += `&category=${category}`;
  if (lessor) queryParams += `&lessor=${lessor}`;
  if (userType) queryParams += `&type=${userType}`;
  if (rental) queryParams += `&rental=${rental}`;

  await apiHeader
    .get(`/products${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// Product Status Update
export const productStatusUpdate = async (values, id) => {
  let data;
  await apiHeader
   .patch(`/products/${id}`, {
    status: values.status,
    message: values.message,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// ========================================== Users ==========================================

// All Quilters
// export const users = async (limit, offset, searchText, searchType, userType, status) => {
export const getAllQuilters = async (limit, offset, searchText, searchType) => {
  let data;
  let queryParams = `?limit=${limit}&offset=${offset}`;
  if (searchText) queryParams += `&type=${searchType}&search=${searchText}`;
  // if (userType) queryParams += `&user_type=${userType}`;

  await apiHeader
    .get(`/quilters/${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// All Vendors
export const getAllVendors = async (limit, offset, searchText, searchType, is_approved) => {
  let data;
  let queryParams = `&limit=${limit}&offset=${offset}`;
  // if (searchText) queryParams += `&search=${searchText}&type=${searchType || ''}`;
  if (searchText) queryParams += `&type=${searchType}&search=${searchText}`;
  // if (userType) queryParams += `&user_type=${userType}`;

  await apiHeader
    .get(`/vendors/?is_approved=${is_approved}${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// Vendor Account Approval
export const userAccountApproval = async (id, status) => {
  let data;
  await apiHeader
   .patch(`vendors/approval/${id}`, {
    "is_approved": status
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// User Status Update
export const userStatusUpdate = async (userType, status, id) => {
  let data;
  await apiHeader
   .patch(`${userType}/status-update/${id}`, {
    active: status,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// Vendor Is Featured Update
export const vendorIsFeaturedUpdate = async (is_featured, id) => {
  let data;
  await apiHeader
   .patch(`vendors/featured/${id}`, {
    "is_featured": is_featured,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// Pending/Approved/Declined Payouts 
export const payouts = async (limit, offset, search, type) => {
  let data;
  await apiHeader
    .get(`/payouts?type=${type}&limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// Pending Payouts Approval
export const payoutApproval = async (values, id) => {
  let data;
  await apiHeader
   .patch(`/payouts/${id}/status`, {
    transaction_ref_id: values.transaction_ref_id,
    remarks: values.remarks,
    status: values.status,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// Categories Main Get
export const categoriesParentGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    // .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .get(`categories/parent?limit=${limit}&offset=${offset}&search=${search?search:""}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Categories Main Create
export const categoriesParentCreate = async (radioValue,values) => {
  let data;

  try {
    const res = await apiHeader.post('categories',{
      parent_id:values?.parent_id ? values?.parent_id : "",
      img:radioValue,
      title:values?.title

    });
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

export const categoriesUpdate = async (radioValue,values,id,parentId) => {
  let data;
  try {
    const res = await apiHeader.put(`categories/${id}`,{
      parent_id:parentId?parentId:"",
      img:radioValue,
      title:values?.title

    });
    data = {
      status: res.status,
      data: res.data,
    };
  } catch (e) {
    if (e.response) {
      data = {
        ...e.response.data,
        status: e.response.status,
      };
    }
  }

  return data;
};

export const categoriesChildtGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    // .get(`cms/term-conditions?limit=${limit}&offset=${offset}&search=${search}`)
    .get(`categories/child?limit=${limit}&offset=${offset}&search=${search?search:""}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


export const categoriesSubChildtGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`categories/sub-child?limit=${limit}&offset=${offset}&search=${search}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Category Delete
export const categoryDelete = async (id) => {
  let data;
  await apiHeader
    .delete(`/categories/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const categorySwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/categories/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};
// Pending Product - Get Parent Categories Listing for Dropdown
export const getParentCatList = async (limit) => {
  let data;
  await apiHeader
    .get(`/categories/parent?limit=${limit}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// View SubAdmins
export const SubAdmins = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/sub-admins/?filter=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};


// 
export const categories = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/categories/?filter=${search}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

// ========================================== Promotion Plans  ==========================================

// View All Promotions
export const getPromotionPlans = async (limit, offset, searchText) => {
  let data;
  let queryParams = `limit=${limit}&offset=${offset}`
  if (searchText) queryParams += `&search=${searchText}`

  await apiHeader
    .get(`/promotions/?${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//  Get Single Promotion
export const getPromotionPlanById = async (id) => {
  let data;
  await apiHeader
    .get(`/promotions/${id}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};


//  Update Promotion
export const updatePromotionPlan = async (id, type, values) => {
  let data;
  await apiHeader
    .put(`/promotions/${id}`, {
      "type": type,
      "title": values.title,
      "per_day_amount": values.per_day_amount,
      "features": values.features
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// ========================================== Promotions  ==========================================

// View All Promotions
export const getPromotions = async (limit, offset, searchText, searchType, promotionType) => {
  let data;
  let queryParams = `limit=${limit}&offset=${offset}`
  if (searchText) queryParams += `&search=${searchText}&type=${searchType}`
  if (promotionType) queryParams += `&promotions_type=${promotionType}`

  await apiHeader
    .get(`/promotions/vendor/request?${queryParams}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

//  Get Single Promotion
export const getPromotionById = async (id) => {
  let data;
  await apiHeader
    .get(`/promotions/vendor/request/${id}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

//  Update Promotion
export const updatePromotion = async (id, values) => {
  let data;
  await apiHeader
    .patch(`/promotions/vendor/request/${id}/validity`, {
      "validity_from": values.start_date,
      "validity_to": values.end_date
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

//  Update Promotion Status
export const updatePromotionStatus = async (id, status) => {
  let data;
  await apiHeader
    .patch(`/promotions/vendor/request/${id}`, {
      status: status
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

// ========================================== FAQs  ==========================================
export const getFaqs = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`/faqs/?${search && `search=${search}`}&limit=${limit}&offset=${offset}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

export const createFaqs = async (values) => {
  let data;
  await apiHeader
    .post("/faqs", {
      title: values?.title,
      description: values?.description,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

export const updateFaqs = async (values) => {
  console.log(values)
  let data;
  await apiHeader
    .put(`/faqs/${values.id}`, {
      title: values?.title,
      description: values?.description,
    })
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.response) {
        data = {
          ...err.response,
        };
      }
    });
  return data;
};

export const deleteFaq = async (id) => {
  let data;
  await apiHeader
    .delete(`/faqs/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

export const updateFaqStatus = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`/faqs/${id}/status?status=${statusActive}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};

export const swapFaqs = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/faqs/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};


// ========================================== Terms & Condition ==========================================
// Terms Get
export const termsGet = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`terms-and-condition?limit=${limit}&offset=${offset}${search && `&search=${search}`}`)
    // .get(`cms/${type}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Terms Create 
export const termsCreate = async (values) => {
  let data;
  await apiHeader
    .post(`terms-and-condition`, {
      title: values.title,
      description: values.description,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Terms Update
export const termsUpdate = async (values) => {
  let data;
  await apiHeader
    .put(`terms-and-condition/${values.id}`, {
      title: values.title,
      description: values.description
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Terms Delete
export const termsDelete = async (id) => {
  let data;
  await apiHeader
    .delete(`terms-and-condition/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Terms Status Update
export const termsStatusUpdate = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`terms-and-condition/${id}/status?status=${statusActive}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};
// Terms Swap
export const termsSwap = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/terms-and-condition/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// ========================================== Privacy Policy ==========================================
// Privacy Policy Get
export const getPrivacyPolicy = async (limit, offset, search) => {
  let data;
  await apiHeader
    .get(`privacy-policy?limit=${limit}&offset=${offset}${search && `&search=${search}`}`)
    // .get(`cms/${type}`)
    .then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Privacy Policy Create 
export const createPrivacyPolicy = async (values) => {
  let data;
  await apiHeader
    .post(`privacy-policy`, {
      title: values.title,
      description: values.description,
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Privacy Policy Update
export const updatePrivacyPolicy = async (values) => {
  let data;
  await apiHeader
    .put(`privacy-policy/${values.id}`, {
      title: values.title,
      description: values.description
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Privacy Policy Delete
export const deletePrivacyPolicy = async (id) => {
  let data;
  await apiHeader
    .delete(`privacy-policy/${id}`).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};

// Privacy Policy Status Update
export const updatePrivacyPolicyStatus = async (id, statusActive) => {
  let data;
  await apiHeader
   .patch(`privacy-policy/${id}/status?status=${statusActive}`)
    .then((res) => {
      data = {
        data: res,
      };
    })
    .catch((err) => {
      if (err.res) {
        data = {
          ...err.res,
        };
      }
    });
  return data;
};
// Privacy Policy Swap
export const swapPrivacyPolicy = async (new_value, new_id) => {
  let data;
  await apiHeader
    .patch(`/privacy-policy/sort-order`, {
      new_value: new_value,
      new_id: new_id,
      
      
    }).then((res) => {
      data = {
        data: res.data,
        status: res.status,
      };
    })
    .catch((e) => {
      if (e.response) {
        data = {
          ...e.response.data,
          status: e.response.status,
        };
      }
    });
  return data;
};